



























import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  name: 'LoopingBG',
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class LoopingBG extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) private logo!: boolean
}
